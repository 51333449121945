<!-- 页面body区域无版芯的布局 -->
<template>
    <div class="other-layout">
        <common-header />
        <div class="router-view" >
            <slot name="routerView" />
            <div class="back-top">
                <back-top/>
            </div>
        </div>
        <common-footer />
    </div>
</template>

<script setup lang="ts">

</script>

<style lang="less" scoped>
.other-layout {

    min-width: 1200px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    // 路由出口
    .router-view {
        width: 100%;
        flex: 1;
        background: #f5f5f5;
        box-sizing: border-box;
        // 回到顶部
        .back-top{
            @apply fixed;
            z-index: 10;
            bottom: 50px;
            right: 0;
        }
    }
}
</style>